
            
            import { removeNetlifyCacheBustParam, runInjectionScript, swapDocument } from "@clerk/astro/internal";

            // Fix an issue with infinite redirect in Netlify and Clerk dev instance
            removeNetlifyCacheBustParam();

            // Taken from https://github.com/withastro/astro/blob/e10b03e88c22592fbb42d7245b65c4f486ab736d/packages/astro/src/transitions/router.ts#L39.
            // Importing it directly from astro:transitions/client breaks custom client-side routing
            // even when View Transitions is disabled.
            const transitionEnabledOnThisPage = () => {
              return !!document.querySelector('[name="astro-view-transitions-enabled"]');
            }

            if (transitionEnabledOnThisPage()) {
              const { navigate, swapFunctions } = await import('astro:transitions/client');

              document.addEventListener('astro:before-swap', (e) => {
                const clerkComponents = document.querySelector('#clerk-components');
                // Keep the div element added by Clerk
                if (clerkComponents) {
                  const clonedEl = clerkComponents.cloneNode(true);
                  e.newDocument.body.appendChild(clonedEl);
                }

                e.swap = () => swapDocument(swapFunctions, e.newDocument);
              });

              document.addEventListener('astro:page-load', async (e) => {
                await runInjectionScript({
                  ...{"clerkJSVariant":"headless","sdkMetadata":{"version":"2.3.2","name":"@clerk/astro","environment":"production"}},
                  routerPush: navigate,
                  routerReplace: (url) => navigate(url, { history: 'replace' }),
                });
              })
            } else {
              await runInjectionScript({"clerkJSVariant":"headless","sdkMetadata":{"version":"2.3.2","name":"@clerk/astro","environment":"production"}});
            }
import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://9a1cbe9418150b219e92ec8c0c692299@o4504293699551232.ingest.us.sentry.io/4506660404002816",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()